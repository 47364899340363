<template>
  <div class="manage-home" :class="{skeleton: !project.loaded}">
    <div class="status">
      <div class="row">
        <div class="col-xl-4 col-md-12" v-for="(s, idx1) in computedSections" :key="idx1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="title font-sm card-title text-uppercase text-muted mb-0">
                    <span>{{ s.title }}</span>
                  </div>
                  <span class="value font-weight-bold mb-0">{{ s.value }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape text-white rounded-circle shadow" :class="s.color">
                    <i class="ni" :class="s.icon"></i>
                  </div>
                </div>
              </div>
              <span class="tails mt-3 mb-0 text-sm" v-if="s.tails.length">
                <span :class="t.class" v-for="(t, idx2) in s.tails" :key="idx2">
                  <span>{{ t.text }}</span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="graph">
      <div class="row">
        <div class="col-lg-6" v-if="state.stats.length">
          <div class="card">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <router-link :to="`/manage/${$route.params.projectType}/${$route.params.projectSeq}/stats`" class="h4 mb-0">{{ $route.params.projectType === "reward" ? "후원자 그래프" : "투자자 그래프" }}</router-link>
                  <span class="ml-1 font-xs">(단위: 명)</span>
                </div>
                <div class="col text-right text-default font-xs color-anchor">
                  <router-link :to="`/manage/${$route.params.projectType}/${$route.params.projectSeq}/stats`">
                    <span>자세히 보기 &nbsp;</span>
                    <i class="fa fa-angle-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="card-body">
              <LineGraph :data="state.stats[0]"/>
            </div>
          </div>
        </div>
        <div class="col-lg-6" v-if="state.stats.length > 1">
          <div class="card">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <router-link :to="`/manage/${$route.params.projectType}/${$route.params.projectSeq}/stats`" class="h4 mb-0">{{ $route.params.projectType === "reward" ? "후원금" : "투자금" }} 그래프</router-link>
                  <span class="ml-1 font-xs">(단위: 원)</span>
                </div>
                <div class="col text-right text-default font-xs color-anchor">
                  <router-link :to="`/manage/${$route.params.projectType}/${$route.params.projectSeq}/stats`">
                    <span>자세히 보기 &nbsp;</span>
                    <i class="fa fa-angle-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="card-body">
              <LineGraph :data="state.stats[1]"/>
            </div>
          </div>
        </div>
        <div class="col-lg-12" v-if="state.stats.length > 2">
          <div class="card">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <router-link :to="`/manage/${$route.params.projectType}/${$route.params.projectSeq}/stats`" class="h4 mb-0">방문자 그래프</router-link>
                  <span class="ml-1 font-xs">(단위: 명)</span>
                </div>
                <div class="col text-right text-default font-xs color-anchor">
                  <router-link :to="`/manage/${$route.params.projectType}/${$route.params.projectSeq}/stats`">
                    <span>자세히 보기 &nbsp;</span>
                    <i class="fa fa-angle-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="card-body">
              <LineGraph :data="state.stats[2]"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import lib from "@/scripts/lib";
import router from "@/scripts/router";
import http from "@/scripts/http";
import LineGraph from "@/components/LineGraph";

function Component(initialize) {
  this.name = "pageManageHome";
  this.initialize = initialize;
}

export default defineComponent({
  components: {LineGraph},
  mixins: [mixin],
  props: {
    project: Object,
  },
  setup(props) {
    const component = new Component(() => {
      const args = {
        startDate: (projectType === "invest" ? props.project.fundingStartDate : props.project.investStartDate)?.substr(0, 10),
        endDate: (projectType === "invest" ? props.project.fundingEndDate : props.project.investEndDate)?.substr(0, 10),
      };

      const today = lib.getDateFormat(new Date(), "yyyy-MM-dd");

      if (args.endDate > today) {
        args.endDate = today;
      }

      http.get(`/api/maker/${projectType}/${projectSeq}/stats/main`, args).then(({data}) => {
        const getStats = (paramArr) => {
          const arr = lib.getRenewed(paramArr);
          const length = arr.length;

          // 배열을 반복하면서 값이 없는 양 끝 요소 삭제
          for (let i = 0; i < length; i += 1) {
            if (!arr[0].value) {
              arr.splice(0, 1);
            } else if (!arr[arr.length - 1].value) {
              arr.pop();
            }
          }

          return arr;
        };

        const keys = Object.keys(data.body);
        keys.sort();

        for (let i = 0; i < keys.length; i += 1) {
          const stats = getStats(data.body[keys[i]]);
          const arr = [];
          let preYear = "";

          for (let j in stats) {
            const date = stats[j].date;
            const dateArr = date.split("-");
            const value = stats[j].value;
            let label = date;

            if (preYear === dateArr[0]) {
              label = [dateArr[1], dateArr[2]].join("-");
            } else {
              preYear = dateArr[0];
            }

            arr.push({label, value});
          }

          state.stats.push(
              {
                labels: arr.map(item => item.label),
                datasets: [
                  {
                    data: arr.map(item => item.value),
                    borderColor: i === 0 ? "#5e72e4" : i === 1 ? "#11cdef" : "#1a174d",
                  },
                ]
              }
          );
        }
      });
    });

    const state = reactive({
      loaded: false,
      stats: [],
    });

    const projectType = router.app.$route.params.projectType;
    const projectSeq = router.app.$route.params.projectSeq;

    const computedSections = computed(() => {
      const sections = [{
        title: ["reward", "mock"].includes(projectType) ? "후원자" : "투자자",
        value: `${lib.getNumberFormat(["reward", "mock"].includes(projectType) ? props.project.investorCnt : props.project.subscribeCount)}명`,
        color: "bg-gradient-primary",
        icon: "ni-single-02",
        tails: [{
          class: "text-success",
          text: `${lib.getNumberFormat(props.project.interestCnt)}명`,
        }, {
          class: "text-nowrap",
          text: "이 관심 프로젝트로 등록했어요."
        }]
      }, {
        title: ["reward", "mock"].includes(projectType) ? "후원 금액" : "투자 금액",
        value: `${lib.getNumberFormat(["reward", "mock"].includes(projectType) ? props.project.expenseAmt : props.project.subscribeAmount)}원`,
        color: "bg-gradient-info",
        icon: "ni-money-coins",
        tails: [{
          class: "text-success mr-1",
          text: `${lib.getNumberFormat(["reward", "mock"].includes(projectType) ? props.project.per : props.project.subscribeRate)}% 달성`
        }, {
          class: "text-nowrap",
          text: `(목표: ${lib.getNumberFormat(["reward", "mock"].includes(projectType) ? props.project.targetAmt : props.project.totAmount)}원)`,
        }]
      }, {
        title: "기간",
        value: projectType === "invest"
            ? `${props.project.fundingStartDate?.substr(0, 10)} ~ ${props.project.fundingEndDate?.substr(0, 10)}`
            : `${props.project.investStartDate?.substr(0, 10)} ~ ${props.project.investEndDate?.substr(0, 10)}`,
        color: "bg-gradient-default",
        icon: "ni-calendar-grid-58",
        tails: [{
          class: "text-success",
          text: projectType === "invest"
              ? Number(props.project.progressOrder) === 1 ? props.project.dday === "0" ? "" : `${lib.getNumberFormat(props.project.dday)}일 ` : ""
              : Number(props.project.progressOrder) === 1 ? props.project.dday === "0" ? "" : `${lib.getNumberFormat(props.project.dday)}일 ` : ""
        }, {
          class: "text-nowrap",
          text: Number(props.project.progressOrder) === 1 ? props.project.dday === "0" ? "오늘 마감" : "남음" : "마감"
        }]
      }, {
        title: "진행 상태",
        value: projectType === "invest"
            ? props.project.displayStateName
            : props.project.progressOrder === "2"
                ? props.project.successFailFlag === "S" ? "펀딩성공" : "펀딩마감"
                : props.project.progressOrder === "1" ? "진행중" : "",
        color: "bg-gradient-green",
        icon: "ni-check-bold",
        tails: [],
      }, {
        title: "프로젝트 유형",
        value: projectType === "invest"
            ? "증권형"
            : projectType === "mock" ? "모의투자" : `후원형 - ${props.project.fundingType === "A" ? "성공해야리워드" : "무조건리워드"}`,
        color: "bg-gradient-orange",
        icon: "ni-folder-17",
        tails: [],
      }, {
        title: "카테고리",
        value: props.project.projectCateName,
        color: "bg-gradient-red",
        icon: "ni-bulb-61",
        tails: [],
      }];

      return sections;
    });

    const computedFundingType = computed(() => {
      switch (props.project.fundingType) {
        case "A":
          return "성공해야 리워드";

        case "K":
          return "무조건 리워드";
      }

      return !state.loaded ? "Wait a moment" : "";
    });

    onMounted(() => {
    });

    return {component, state, computedFundingType, computedSections};
  }
});
</script>

<style lang="scss" scoped>
.manage-home {
  position: relative;

  > .status {
    .card {
    }
  }

  .graph {
    .card-header {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  &.skeleton {
    > .status > .row {
      .card > .card-body {
        .title > span,
        .value,
        .icon, .tails > span {
          @include skeleton;
        }
      }
    }
  }
}
</style>